import React from 'react';
import { STORE } from "./constants"
import { inject, observer } from "mobx-react";
import { Accordion, Button, Card, Container, Row } from 'react-bootstrap';
import { ComicData, DiscountData, OfferCode } from './Store';
import DOMPurify from 'dompurify';


@inject(STORE)
@observer
export default class Comic extends React.Component<any, any> {
  constructor(props:any) {
    super(props);
    this.state = {buttonClicked: false};
  }

  makeDownloadLink(c:OfferCode, i:number) {
    if(c.wasUsed)
      return <div key={i}><a className="Comic-title-link" href ={c.downloadLink} target="_blank" rel="noreferrer"><del>{c.percentOff}% off!</del> </a><ins>ALREADY USED</ins></div>
    else 
      return <a className="Comic-title-link" key={i} href ={c.downloadLink} target="_blank" rel="noreferrer">CLICK HERE to download for {c.percentOff}% off!</a>
    
    }

  NoFreeComicDownloadedYet(codes:OfferCode[]):boolean {
    let wasDownloaded = false;
    if (codes.length > 0) {
      for(let code of codes) {
        if (code.percentOff === 100) {
          wasDownloaded = true;
        }
      }
    }

    return !wasDownloaded
  }

  showDiscountData(discountData:DiscountData, comicData:ComicData, codes:OfferCode[]) {
    return <div>
    <Card.Text style={{ fontWeight: "bold" }}>
         Original Price: ${comicData.price / 100}
    </Card.Text>
    <Card.Text>
      { (comicData.newestComic && discountData.subscriptionTier >=2) ? 
      "Your subscription perk: DOWNLOAD FOR FREE!" : 
      `Your subscription discount: ${discountData.percentOff}% off`
      }
    </Card.Text>
    <Card.Text style={{ fontWeight: "bold", color: "lightgreen" }}>Discount Price: ${(comicData.price / 100) * (discountData.percentOff / 100)}</Card.Text>

    <Card.Footer>
      <Container>
        <Row>
          {  codes.map((c, i) => (
             this.makeDownloadLink(c,i)
           )) }

           {comicData.newestComic && this.NoFreeComicDownloadedYet(codes) && discountData.subscriptionTier >= 2 ?
                     <Button variant="warning" onClick={() => this.props.app.generateDiscountCode(comicData)}>
                     Download for FREE!
                   </Button> 
          : (codes && codes.length === 0) ? 
          <Button variant="warning" onClick={() => this.props.app.generateDiscountCode(comicData)}>
          Download for discounted price!
        </Button> : null
         }

        </Row>
      </Container>
    </Card.Footer> </div>
  }

  showNoDiscount(comicData:ComicData) {
    return <div>
    <Card.Text>
        No discount available.  Increase your subscription tier to enable discounts!
    </Card.Text>
    <Card.Text style={{ fontWeight: "bold" }}>
        Price: ${comicData.price / 100}
    </Card.Text>
   </div>
  }
  
    public render() {

      const comicData:ComicData = this.props.comicData;
      const codes:OfferCode[] = comicData ? (comicData.codes ? comicData.codes : []) : []
      const discountData:DiscountData = this.props.app.discountData;
      const issues:string[] = discountData ? (discountData.issues ? discountData.issues : []) : ["Error calculating discount"];
      const discountActive:boolean = issues.length === 0 && discountData.subscriptionTier > 0;
 
        return (
          <Card bg="dark" text="white" border="dark">
          <Card.Img variant="top" src={comicData.coverImage} />

          <Card.Body>
            <Card.Title>
              <a
                className="Comic-title-link"
                target="_blank"
                rel="noreferrer"
                href={comicData.purchaseLink}
              >
                {comicData.name}
              </a>
            </Card.Title>

            <Accordion className="panel-header">
              <Accordion.Item className="panel-wrap" eventKey="0">
                <Accordion.Header className="panel-header">
                  See Description
                </Accordion.Header>
                <Accordion.Body className="panel-body">
                  <Card.Text
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(comicData.description),
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {discountActive ? this.showDiscountData(discountData, comicData, codes): this.showNoDiscount(comicData)}
          </Card.Body>
        </Card>
        )
    }
}


