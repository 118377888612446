import React from 'react';
import { STORE } from "./constants"
import { inject, observer } from "mobx-react";
import { Button } from 'react-bootstrap';


@inject(STORE)
@observer
export default class AuthErrorPage extends React.Component<any, any> {

    public render() {
        return (
            <div className="App">
                <header className="App-header">
                    <h2>There was a problem verifying your account.</h2>
                    <p className="App-error">Error: {this.props.error.error}</p>
                    <p className="App-error">{this.props.error.error_description} </p>
                    <Button href="/login" onClick={this.props.app.clearSessionInfo}>Return to login page</Button>
                </header>
            </div>
        )
    }
}


