import React from 'react';
import ReactDOM from 'react-dom';
//import './css/index.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { Provider } from 'mobx-react';
import {createStores} from "./CreateStore"
import RouteStores from './RouteStores';
import { configure } from "mobx"

if(process.env.NODE_ENV !== "production" ) {
    configure({
      enforceActions: "always",
      computedRequiresReaction: true,
      reactionRequiresObservable: true,
      observableRequiresReaction: true,
      disableErrorBoundaries: true
  })
}

const rootStore = createStores();


ReactDOM.render(
  <Provider {...rootStore}>
    <React.StrictMode>
      <Router>
        <RouteStores />
      </Router>
    </React.StrictMode>
    </Provider>,
  document.getElementById('root')
);

