import { action, makeObservable, observable, runInAction } from "mobx"
import { API } from 'aws-amplify'

const amplifyAppName = process.env.REACT_APP_API_NAME;
const amplifyEndpoint = process.env.REACT_APP_API_ENDPOINT;

API.configure({
  API: {
    endpoints: [
        {
            name: amplifyAppName,
            endpoint: amplifyEndpoint
        }
    ]
}
});

export interface AuthError {
    error: String;
    auth_error: String;
}

export interface LoginCodeResponse {
    access_token: string
    expires_in: number
    error: string
}
export interface VerifyCodeResponse {
    verified: boolean;
    error: string;
}

export interface ComicData {
    name: string;
    description: string;
    id: string;
    coverImage: string;
    purchaseLink: string;
    price: number;
    discountLinkTier: number;
    discountLink: string;
    codes: OfferCode[];
    newestComic: boolean;
}

export interface DiscountData {
    subscriptionTier: number;
    percentOff: number;
    issues: string[];
}

export interface OfferCode {
    downloadLink:     string;      
	percentOff:       number;      
	wasUsed:          boolean;         
	tier:             number;         
}

export interface User {
    name: string;
    avatar_url?: string | null;
    email: string;
    email_verified: boolean;
    id: string;
    signed_up_at: Date;
  }

export interface EdgesEntity {
    node: Payment;
  }

  export interface Payments {
    edges: (EdgesEntity)[] | null;
  }

export interface Payment {
    id: string;
    amount: number;
    authorized_at: Date | null;
    captured_at?: Date | null;
    comment?: string | null;
    disputed: boolean;
    failed: boolean;
    recurring: boolean;
    refunded_amount: number;
    success: boolean;
    tip: boolean;
    under_review: boolean;
  }

export interface Subscription {
    id: string;
    active: boolean;
    billing_failed: boolean;
    billing_failed_at: Date | null;
    cancelled: boolean;
    client_application_id: string | null;
    created_at: Date;
    last_time_charged_at: Date | null;
    price: number;
    trusted: boolean;
  }

  export interface Subscriber {
    payments: Payments;
    subscription: Subscription;
  }

  export interface Data {
    user: User;
    subscriber: Subscriber;
  }

export interface Profile {
    data: Data;
}

export interface ErrorDetails {
    error: string;
    error_description:string
}




export default class Store {

    //@observable public imagePaths:string[];
    @observable public isAuthenticated: boolean = false;
    @observable public userProfile = null;
    @observable public comicList: ComicData[] = [];
    @observable public showProfileModal: boolean = false;
    @observable public profile:Profile = ({} as any) as Profile;
    @observable public authError:boolean = false;
    @observable public errorDetails:ErrorDetails = ({} as any) as ErrorDetails;
    @observable public discountData:DiscountData = ({} as any) as DiscountData;

    //please wait bool
    @observable public showSpinner:boolean = false;

    private accessToken: string = "";
    private sessionVerified: boolean = false;

    constructor() {
        makeObservable(this)
        this.checkForActiveSession();
        // this.imagePaths = observable([]);
    }
    //?error=access_denied&error_description=The+resource+owner+or+authorization+server+denied+the+request.


    /*
    @action public toggleTest = () => {
        this.testBool = !this.testBool;
        console.log("New value: " + this.testBool);

    }*/

    @action public toggleProfileModal = () => {
        this.showProfileModal = !this.showProfileModal;
    }

    @action public logoutActionTriggered = () => {
        localStorage.clear();
        this.isAuthenticated = false;
        this.userProfile = null;
        this.comicList = [];
        this.showProfileModal = false;
        this.accessToken = "";
    }

    @action public clearSessionInfo = async () => {
        this.isAuthenticated = false;
        this.errorDetails =  ({} as any) as ErrorDetails;
        this.authError = true;
        this.accessToken = "";
        this.sessionVerified = false;
        localStorage.clear();
    }


    @action public checkForActiveSession = async () => {
        //console.log("checking if session is active...")
        let session = await this.GetSession();
        if (session !== null && session !== "") {
            try {
                runInAction(()  => {
                    this.showSpinner = true;
                })
                let verifySessionResponse:VerifyCodeResponse = await this.verifySession(session) as VerifyCodeResponse;
                if (verifySessionResponse && verifySessionResponse.verified) {
                    runInAction(async ()  => {
                        this.accessToken = session;
                        this.profile = await this.getProfile() as Profile;
                        this.profile = await this.getProfile() as Profile;
                        this.discountData = await this.getDiscountData() as DiscountData;
                        this.comicList = await this.getComicsDataList() as ComicData[];
                        this.sessionVerified = true;
                        this.isAuthenticated = true;
                        this.showSpinner = false;
                    })
                } else {
                    this.isAuthenticated = false;
                    this.errorDetails = {error: "session_error", error_description: verifySessionResponse.error} as ErrorDetails; 
                    this.authError = true;
                    this.accessToken = "";
                    this.sessionVerified = false;
                    this.showSpinner = false;
                }
            } catch (e) {
                this.isAuthenticated = false;
                this.errorDetails = {error: "unexpected error", error_description: "Sorry, there is an internal problem at the moment.. please try again later"} as ErrorDetails; 
                this.authError = true;
                this.accessToken = "";
                this.sessionVerified = false;
                this.showSpinner = false;
            }


        } else {
            this.isAuthenticated = false;
            this.accessToken = "";
        }
    }

    @action public generateDiscountCode = async (cd:ComicData) => {
        let newCode:OfferCode = await this.createDiscountCode(cd) as OfferCode;
        if (cd.codes) {
            cd.codes.push(newCode)
        } else {
            runInAction(() => {
                cd.codes = observable([newCode])
            })
        }
    }

    public BrowserQueryString() {
        return new URLSearchParams(document.location.search.substring(1));
    }

    private async CreateSession(code: String) {
        if (code) {

            //Test the code
            try {
                let response = await this.loginCode(code) as LoginCodeResponse;
                if (response != null) {
                    if (response.error !== "") {
                        this.errorDetails = {error: "invalid_token", error_description: response.error} as ErrorDetails; 
                        this.authError = true;
                        return null;
                    } else {                
                        let access_token = response.access_token;
                        let expires_in = response.expires_in;
                       // let error = response.error
                        //console.log("Got the response:", response)
                        //console.log("access_token=", access_token)
                        //console.log("expires_in=", expires_in)
                       // console.log("error=", error)
                        let today = new Date();
    
                        var object = { code: access_token, created: new Date().getTime(), expires: new Date(today.getTime() + expires_in).getTime() }
                        localStorage.setItem("session", JSON.stringify(object));
                        //console.log("Creating code", JSON.stringify(object))
                        return object.code;
                    }
                }
            } catch (error) {
                this.errorDetails = {error: "unexpected error", error_description: "There was a problem authenticating your account.  Please try agian"} as ErrorDetails; 
                this.authError = true;
                this.showSpinner = false;
                this.isAuthenticated = false;
                this.sessionVerified = false;
                this.showSpinner = false;
                return null;
            }
        }
    }

    private async GetSession() {
        let session = localStorage.getItem("session");

        if (session) {
            let object = JSON.parse(session);
            let createdDate = object.created;
            let ttl = object.expires;

            let today = new Date();
            let expireDate = new Date(today.getTime() - ttl);


            if (createdDate < expireDate) {
                //console.log("The session has expired, clearing ");
                localStorage.clear();
                return null;
            } else {
                //console.log("The session has not expired yet");
            }
           // console.log("There was a session");
           // console.log("session", session)
            return object.code
        } else {
            let query = this.BrowserQueryString();
            let code = query.get("code");
            let error = query.get("error");
            let error_description = query.get("error_description");
           // console.log("code ", code, " error ", error, " error_description ", error_description);
            if (error) {
                this.errorDetails = {error: error, error_description: error_description} as ErrorDetails; 
                this.authError = true;
            } else if (code) {
                let session = await this.CreateSession(code)
                if (session) {
                    return session
                }
            }
        }

        return null
    }

    private async getComicsDataList() {
        try {
            let raw
            if(process.env.NODE_ENV === "production") {
                raw = await API.post(amplifyAppName, "/comics/list", { // OPTIONAL
                    headers: {}, // OPTIONAL
                    body: JSON.stringify({code: this.accessToken}),
                  })
            } else {
                raw = await fetch('/comics/list', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({code: this.accessToken}),
                    mode: 'no-cors'
                }).then(res => res.json()) 
            }
            //console.log("got response: " + raw)
            return raw
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    private async getDiscountData() {
        try {
            let raw
            if(process.env.NODE_ENV === "production") {
                raw = await API.post(amplifyAppName, "/check/discount", { // OPTIONAL
                    headers: {}, // OPTIONAL
                    body: JSON.stringify({code: this.accessToken}),
                  })
            } else {
                raw = await fetch('/check/discount', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({code: this.accessToken}),
                    mode: 'no-cors'
                }).then(res => res.json())
            }

            //console.log("got response: " + raw)
            return raw
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    private async createDiscountCode(cd:ComicData) {
        try {
            let raw
            if(process.env.NODE_ENV === "production") {
                raw = await API.post(amplifyAppName, '/comic/'+cd.id+'/createCode', { // OPTIONAL
                    headers: {}, // OPTIONAL
                    body: JSON.stringify({code: this.accessToken}),
                  })
            } else {
                raw = await fetch('/comic/'+cd.id+'/createCode', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({code: this.accessToken}),
                    mode: 'no-cors'
                }).then(res => res.json())
            }


           // console.log("got response: " + raw)
            return raw
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    private async loginCode(code: String) {
        try {

            let raw
            if(process.env.NODE_ENV === "production") {
                raw = await API.post(amplifyAppName, "/login", { // OPTIONAL
                    headers: {}, // OPTIONAL
                    body: JSON.stringify({code: code}),
                  })
            } else {
                raw = await fetch('/login', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({code: code}),
                    mode: 'no-cors'
                }).then(res => res.json())
            }

           // console.log("got response: " + raw)
            return raw
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    private async verifySession(code: String) {
        try {
            let raw
            if(process.env.NODE_ENV === "production") {
                raw = await API.post(amplifyAppName, "/verifySession", { // OPTIONAL
                    headers: {}, // OPTIONAL
                    body: JSON.stringify({code: code}),
                  })
            } else {
                raw = await fetch('/verifySession', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({code: code}),
                    mode: 'no-cors'
                }).then(res => res.json())
            }

            //console.log("got response: " + raw)
            return raw
        } catch (error) {
            console.log(error);
            throw error
        }
    }


    private async getProfile() {
        try {
            let raw
            if(process.env.NODE_ENV === "production") {
                raw = await API.post(amplifyAppName, "/profile", { // OPTIONAL
                    headers: {}, // OPTIONAL
                    body: JSON.stringify({code: this.accessToken}),
                  })
            } else {
                raw = await fetch('/profile', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({code: this.accessToken}),
                    mode: 'no-cors'
                }).then(res => res.json())
            }
        
            this.userProfile = raw;

           // console.log("got response: " + raw)
            return raw
        } catch (error) {
            console.log(error);
            throw error
        }
    }

}