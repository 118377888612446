import React from 'react';
import {STORE} from "./constants"
import {inject, observer} from "mobx-react";
import Comics from "./Comics"
import LoginPage from "./LoginPage"
//import "./css/App.css"
import "./css/Magnet.css"

import {
    BrowserRouter as Router, 
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
import PleaseWait from './PleaseWait';

@inject(STORE)
@observer
export default class RouteStores extends React.Component<any, any> {

    public render() {
        const isAuthenticated:boolean = this.props.app.isAuthenticated;
       // console.log("RouteStore value for isAuthenticated:", isAuthenticated)

        return (
            <Router >
            <div>
          {/**  <div>auth = { String(isAuthenticated)}</div>*/} 
              <Switch>
                <Route exact path="/">
                  {isAuthenticated ? <Redirect to="/comics"  /> : <Redirect to="/login" />}
                </Route>
                <Route path="/comics">
                  {isAuthenticated ? <Comics /> : <Redirect to="/login" />}
                </Route>
                <Route path="/verify_login" >
                  {isAuthenticated ? <Redirect to="/comics" /> : <PleaseWait />}
                </Route>
                <Route path="/login">
                   {isAuthenticated ? <Redirect to="/comics" /> : <LoginPage />}
                </Route>
                <Route>
                 {isAuthenticated ? <Redirect to="/comics" /> : <Redirect to="/login" />}
                </Route>
              </Switch>
              <footer className="Footer">Copyright © {new Date().getFullYear()} MercyMagnet All rights reserved</footer>
            </div>
          </Router>
            )
    }
}


