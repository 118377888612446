import React from "react";
import { STORE } from "./constants";
import { inject, observer } from "mobx-react";
import { Container, Dropdown, Navbar } from "react-bootstrap";
import { Profile } from "./Store";

@inject(STORE)
@observer
export default class TopNavBar extends React.Component<any, any> {
  public render() {
    const profile: Profile = this.props.app.profile;
    const subscriberName = profile ? profile.data.user.name : null;

    return (
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>
            <h2  >MercyMagnet Subscriber Discounts!</h2>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Dropdown className="justify-content-end">
            <Dropdown.Toggle
              id="dropdown-button-dark-example1"
              variant="secondary"
            >
              {subscriberName}
            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
              <Dropdown.Item onClick={this.props.app.toggleProfileModal}>
                View my Profile
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={this.props.app.logoutActionTriggered}>
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </Navbar>
    );
  }
}
