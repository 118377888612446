import React from 'react';
import { STORE } from "./constants"
import { inject, observer } from "mobx-react";
import { Image, Spinner } from 'react-bootstrap';


@inject(STORE)
@observer
export default class LoginPage extends React.Component<any, any> {

  public render() {
    const showSpinner: boolean = this.props.app.showSpinner;

    return (
      <div className="App">
        {showSpinner ?
          <div className="App-header"><Spinner animation="border" variant="light" /> Please Wait...
          </div> :
          <div >
            <header className="App-header">
              <div>
                <Image src={process.env.PUBLIC_URL + "/logo192.png"} roundedCircle />
                <p>
                  MercyMagnet Comic discounts for <a className="App-link" href="https://subscribestar.adult/mercymagnet/subscribe" target="_blank" rel="noreferrer">Subscribers</a>
                </p>
                <p> <a
                  className="App-link"
                  href={`https://www.subscribestar.com/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=subscriber.read+subscriber.payments.read+user.read+user.email.read`}
                >
                  Sign in
                </a> to get comics!</p>
              </div>
            </header>
          </div>
        }
      </div>
    )
  }
}


