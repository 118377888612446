import React from "react";
import { STORE } from "./constants";
import { inject, observer } from "mobx-react";
import {
  CardGroup,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ComicData } from "./Store";

//import "./css/Comics.css";
import ComicsWelcomeAccordian from "./ComicsWelcomeAccordian";
import Comic from "./Comic";
import TopNavBar from "./TopNavBar";
import ProfilePage from "./ProfilePage";

@inject(STORE)
@observer
export default class Comics extends React.Component<any, any> {
  public render() {
    const comics = this.props.app.comicList as ComicData[];

 
    return (
      <div className="Comic-bgcolor">
        <TopNavBar />

      <ProfilePage />
        
        <Container fluid>
          <Row>
            <Image src="https://cdn.slushe.com/misc/605535efb044cslushe-mercymagnet-2.png" />
          </Row>
        </Container>

        <Container>
          <Row>
            <ComicsWelcomeAccordian />
            <CardGroup>
              <Row xs={1} md={3} className="g-4">
                {comics.map((comic, idx) => (
                  <Col key={idx}>
                    <Comic key={idx} comicData={comic} />
                  </Col>
                ))}
              </Row>
            </CardGroup>
          </Row>
        </Container>
      </div>
    );
  }
}
