import React from "react";
import { STORE } from "./constants";
import { inject, observer } from "mobx-react";
import { Accordion,  Figure,  Table } from "react-bootstrap";
//import "./css/ProfileGrid.css";
import { DiscountData, Profile } from "./Store";

@inject(STORE)
@observer
export default class ProfileGrid extends React.Component<any, any> {
  parseDate(date: Date | null) {
    return date ? new Date(date).toDateString() : "Never";
  }

  getTimeSince(date: Date | null) {
    if (date) {
      let today = new Date();
      let lastCharged = new Date(date);
      let elapsedTime = today.getTime() - lastCharged.getTime()
      var Difference_In_Days = elapsedTime / (1000 * 3600 * 24);

      return "" + Math.round(Difference_In_Days) + ""

    }
    return "Unknown"
  }

  getTier(price: number) {
    let ret = ""
    let image = ""
    if (price <= 0) {
      ret = "FREE tier";
      image = "/Follower.png";
    } else if (price <= 500) {
      ret = "Goblin Tier";
      image = "/Goblin.jpg";
    } else if (price <= 1000) {
      ret = "Orc Tier";
      image = "/Orc.png";
    } else if (price <= 2000) {
      ret = "Demon Tier";
      image = "/Demon.png";
    } else {
      ret = "$" + (price / 100) + " per month";
      image = "/Demon.png";
    }

    

    return <Figure>
      <Figure.Image
        width={75}
        height={75}
        src={process.env.PUBLIC_URL + image}
      />
      <Figure.Caption style={{color: "white"}}>{ret}</Figure.Caption>
    </Figure>
  }

  displayDiscount(data:DiscountData) {
    if(data) {
      let discountList = this.getTierRewards(data.subscriptionTier, data.percentOff)
      return (
        <ul>
            { discountList.map((discount, i) => (
          <li key={i}>{discount}</li>
            )) }
        </ul>
      )
    }
  }

  getTierRewards(tier: number, percent: number):string[] {
    if ( tier === 0) {
      return ["No Discount"]
    } else if (tier === 1) {
      return ["" + percent +"% off all comics"];
    } else if (tier === 2) {
      return ["FREE download of latest comic",
       "" + percent +"% off all comics!"];
    } else if (tier === 3) {
      return ["FREE download of latest comic",
       ""+ percent +"% off all comics",
       "Request a custom comic"];
    }
    return ["unable to display rewards"]
  }
  public render() {
    const discountData:DiscountData = this.props.app.discountData;
    const issues:string[] = discountData ? (discountData.issues ? discountData.issues : []) : ["Error calculating discount"];
    const profile: Profile = this.props.app.profile;
    const data = profile ? profile.data : null;
    const user = data ? data.user : null;
    const subscriber = data ? data.subscriber : null

    const name = user ? user.name : null;
    const email = user ? user.email : null;
    const email_verified = user ? user.email_verified : null;
    const id = user ? user.id : null;
    const signed_up_at = this.parseDate(user ? user.signed_up_at : null);

    const payments = subscriber ? subscriber.payments : null
    const num_of_payments = payments ? (payments.edges ? payments.edges.length : 0) : 0;

    const subscription = subscriber ? subscriber.subscription : null;
    const active_subscriber = subscription ? subscription.active : null;
    const billing_failed = subscription ? subscription.billing_failed : null;
    const billing_failed_at = this.parseDate(subscription ? subscription.billing_failed_at : null);
    const cancelled = subscription ? subscription.cancelled : null;
    const subscription_date = this.parseDate(subscription ? subscription.created_at : null);
    const last_time_charged = this.parseDate(subscription ? subscription.last_time_charged_at : null);
    const time_since_last_charge = this.getTimeSince(subscription ? subscription.last_time_charged_at : null);

    const subscription_price = subscription ? subscription.price : 0;

    const subscription_tier = this.getTier(subscription_price);
    const trusted_subscriber = subscription ? subscription.trusted : null

    const discount_calculation =
      issues.length > 0
        ?  <div style={{color: "pink"}} >No Discounts</div>
        : <div style={{color: "#78E830"}} >{this.displayDiscount(discountData)}</div>

    return (
      <div>
        <Table striped bordered hover variant="dark">
          <tbody>
            <tr>
              <td>Name</td>
              <td>{name}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{email}</td>
            </tr>
            <tr>
              <td>Subscription Tier</td>
              <td>{subscription_tier}</td>
            </tr>
            <tr>
              <td>Number of Paymets</td>
              <td>{num_of_payments}</td>
            </tr>
            {billing_failed ? (
              <pre>
                <tr>
                  <td>Billing Failed</td>
                  <td>{String(billing_failed)}</td>
                </tr>
                <tr>
                  <td>Billing Failed At</td>
                  <td>{billing_failed_at}</td>
                </tr>
              </pre>
            ) : null}

            {cancelled ? (
              <tr>
                <td>Cancelled</td>
                <td>{String(cancelled)}</td>
              </tr>
            ) : null}

            <tr>
              <td>Last Charge Was</td>
              <td>{last_time_charged}</td>
            </tr>
            <tr>
              <td>Days since last charge</td>
              <td>{time_since_last_charge}</td>
            </tr>
            <tr>
              <td>Discounts Applied</td>
              <td>{discount_calculation}</td>
            </tr>
            { issues.length > 0 ?             
            <tr>
              <td >Issues with account</td>
              <td>
              <ul style={{color: "pink"}}>
              { issues.map((issue, i) => (
                
                  <li>{issue}</li>
              ))}
              </ul>
              </td>
            </tr> : null
            }
          </tbody>
        </Table>
        <Accordion className="panel-header">
          <Accordion.Item className="panel-wrap" eventKey="0">
            <Accordion.Header className="panel-header">
              Details
            </Accordion.Header>
            <Accordion.Body className="panel-body">
              <Table striped bordered hover variant="dark">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{name}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{email}</td>
                  </tr>
                  <tr>
                    <td>Email Verified</td>
                    <td>{String(email_verified)}</td>
                  </tr>
                  <tr>
                    <td>Id</td>
                    <td>{id}</td>
                  </tr>
                  <tr>
                    <td>Signed Up At</td>
                    <td>{signed_up_at}</td>
                  </tr>
                  <tr>
                    <td>Payment Count</td>
                    <td>{num_of_payments}</td>
                  </tr>
                  <tr>
                    <td>Active Subscriber</td>
                    <td>{String(active_subscriber)}</td>
                  </tr>
                  <tr>
                    <td>Billing Failed</td>
                    <td>{String(billing_failed)}</td>
                  </tr>
                  <tr>
                    <td>Billing Failed At</td>
                    <td>{billing_failed_at}</td>
                  </tr>
                  <tr>
                    <td>Cancelled</td>
                    <td>{String(cancelled)}</td>
                  </tr>
                  <tr>
                    <td>Subscribe Date</td>
                    <td>{subscription_date}</td>
                  </tr>
                  <tr>
                    <td>Last Charge Was</td>
                    <td>{last_time_charged}</td>
                  </tr>
                  <tr>
                    <td>Subscription Tier</td>
                    <td>{subscription_tier}</td>
                  </tr>
                  <tr>
                    <td>Trusted</td>
                    <td>{String(trusted_subscriber)}</td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  }
}
