import React from 'react';
import { STORE } from "./constants"
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router-dom';
import AuthErrorPage from './AuthErrorPage';
import { Spinner } from 'react-bootstrap';


@inject(STORE)
@observer
export default class PleaseWait extends React.Component<any, any> {
    public render() {
        const authError = this.props.app.authError;
        const errorDetails = this.props.app.errorDetails;
       // console.log("Checking for auth error...", authError)

        return (
            <div className="App">
                {authError ? <AuthErrorPage error={errorDetails} />
                    : <div>
                        <Redirect to="/verify_login" />
                        <div className="App-header"><Spinner animation="border" variant="light" /> Please Wait...</div>
                    </div>
                }
            </div>
        )
    }
}


