import React from "react";
import { STORE } from "./constants";
import { inject, observer } from "mobx-react";
import {
  Accordion,
  Button,
  OverlayTrigger,
  Popover,
  Table,
} from "react-bootstrap";

@inject(STORE)
@observer
export default class ComicsWelcomeAccordian extends React.Component<any, any> {
  public render() {
    const custom_comic_details = (
      <Popover id="popover-basic">
        <Popover.Header as="h3">Custom Comic Restrictions</Popover.Header>
        <Popover.Body>
          <ul>
            <li>
              <h6>
                You may only request 1 comic every 6 months of consecutive{" "}
                <strong>Demon tier</strong> subscription status.
              </h6>
            </li>
            <li>
              <h6>
                We <strong>both</strong> must agree upon the contents of the
                comic.
              </h6>
            </li>
            <li>
              <h6>
                The comic will <strong>not</strong> be private. (I will list and
                sell copies of the comic once completed)
              </h6>
            </li>
            <li>
              <h6>
                Comics longer than 20 renders will incur additional costs.
              </h6>
            </li>
          </ul>
        </Popover.Body>
      </Popover>
    );

    return (
      <div>
        <Accordion
          defaultActiveKey="0"
          style={{ padding: 30, background: "inherit" }}
          className="panel-header"
        >
          <Accordion.Item eventKey="0" className="panel-wrap">
            <Accordion.Header className="panel-header">
              <h3>Welcome! Please Read</h3>
            </Accordion.Header>
            <Accordion.Body className="panel-body">
              <div>
                <h5>Welcome to my comic discount page!</h5> If you are a
                subscriber of mine, you can use this website to download my
                comics. This website pulls directly from <a className="Comic-title-link" target="_blank" rel="noreferrer" href="https://subscribestar.adult/mercymagnet">My Subscribestar Page</a> and verifies your subscription automatically. My comics are
                pulled directly from <a className="Comic-title-link" target="_blank" rel="noreferrer" href="https://mercymagnet.gumroad.com/">Gumroad</a> so my latest comics will always be available here!{" "}
                <h5>Your discounts are based on your subscription tier:</h5>
                  <Table striped bordered hover variant="dark">
                    <tbody>
                      <tr>
                        <td>
                          <h6>
                            <strong>Follower (FREE tier)</strong>
                          </h6>
                        </td>
                        <td>
                          There are no discounts for just followers, sorry!
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6>
                            <strong>Goblin ($5 tier)</strong>
                          </h6>
                        </td>
                        <td>All comics are %50 off!</td>
                      </tr>
                      <tr>
                        <td>
                          <h6>
                            <strong>Orc ($10 tier)</strong>
                          </h6>
                        </td>
                        <td>
                          Download the <strong>NEWEST</strong> comic for free!{" "}
                          <strong>+ Previous tier rewards!</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6>
                            <strong>Demon ($20 tier)</strong>
                          </h6>
                        </td>
                        <td>
                          Request a custom comic!{" "}
                          <strong>+ Previous tier rewards! </strong>
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            overlay={custom_comic_details}
                          >
                            <Button variant="success"> See Details </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                <h5>Thank you! Enjoy the comics!</h5>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header className="panel-header">
              <h3>Frequently Asked Questions</h3>
            </Accordion.Header>
            <Accordion.Body className="panel-body">
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>
                      <h4>Question</h4>
                    </th>
                    <th>
                      <h4>Answer</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>How often do you release new comics?</td>
                    <td>
                      Whenever I can! Usually averaging one comic per month.
                    </td>
                  </tr>
                  <tr>
                    <td>I just subscribed but I don't have any discounts.</td>
                    <td>
                      Ensure your payment has been fully processed. If your
                      latest payment is still pending, processing, cancelled, or
                      refunded, then the discount won't apply. You can check
                      your{" "}
                      <button onClick={this.props.app.toggleProfileModal}>
                        <strong>
                          <u>profile</u>
                        </strong>
                      </button>{" "}
                      for a breakdown of what the problem is.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      I'm a $10+ subscriber, how can I donload all of your
                      comics for free?
                    </td>
                    <td>
                      My free download only applies to the most recently
                      released comic. Older comics can be downloaded with
                      discount codes. If you are a new $10+ subscriber then you
                      will be able to download my latest comic for free, and any
                      new ones in the future (while your subscription is
                      active).
                    </td>
                  </tr>
                  <tr>
                    <td>How can I contact you?</td>
                    <td>Send me a message on subscribestar.</td>
                  </tr>
                  <tr>
                    <td>Do you take commission requests?</td>
                    <td>
                      Sometimes yes, shoot me a message on subscribestar to
                      discuss.
                    </td>
                  </tr>
                  <tr>
                    <td>Can I use the discount codes multiple times?</td>
                    <td>No, the codes are 1 time use only.</td>
                  </tr>
                  <tr>
                    <td>
                      I lost my loign information on gumroad and cant get the
                      comics I previously purchased. Can I get new download
                      links?
                    </td>
                    <td>
                      No, sorry, ensure you don't lose your login information on
                      Gumroad.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  }
}
