import React from "react";
import { STORE } from "./constants";
import { inject, observer } from "mobx-react";
import { Button, Modal } from "react-bootstrap";
import ProfileGrid from "./ProfileGrid";
import { Profile } from "./Store";

@inject(STORE)
@observer
export default class ProfilePage extends React.Component<any, any> {
  public render() {
    const profile: Profile = this.props.app.profile;
    const subscriberName = profile ? profile.data.user.name : null;

    return (
      <Modal
        className="my-modal"
        dialogClassName="modal-90w"
        show={this.props.app.showProfileModal}
        onHide={this.props.app.toggleProfileModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{subscriberName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProfileGrid />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.props.app.toggleProfileModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
